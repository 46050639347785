<template>
  <div class="c-select">
    <el-form-item v-if="(userCategory === 0) && (selectableShop)">
      <el-select v-model="select_company.company_id" value-key="companyName" filterable
        :placeholder="$t('COMMON.COMPONENT.SELECT_COMPANY.PLACEHOLDER')" @change="changeCompany()" class="company-select">
        <el-option v-for="item in companyList" :key="item.company_id" :label="item.company_name"
          :value="item.company_id"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item v-if="(showShop !== false) && (selectableShop)">
      <el-select v-model="select_shop.shop_id" value-key="shopName" filterable
        :placeholder="$t('COMMON.COMPONENT.SELECT_SHOP.PLACEHOLDER')" @change="changeShop()" class="shop-select">
        <el-option v-for="item in shopList" :key="item.shop_id" :label="item.shop_name" :value="item.shop_id"></el-option>
      </el-select>
    </el-form-item>
    <!-- 非アクティブ版会社名、店舗名ドロップダウンリスト -->
    <el-form-item v-if="(userCategory === 0) && (!selectableShop)">
      <el-select v-model="select_company.company_id" value-key="companyName" filterable disabled
        :placeholder="$t('COMMON.COMPONENT.SELECT_COMPANY.PLACEHOLDER')" @change="changeCompany()" class="company-select">
        <el-option v-for="item in companyList" :key="item.company_id" :label="item.company_name"
          :value="item.company_id"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item v-if="(showShop !== false)&& (!selectableShop)">
      <el-select v-model="select_shop.shop_id" value-key="shopName" filterable disabled
        :placeholder="$t('COMMON.COMPONENT.SELECT_SHOP.PLACEHOLDER')" @change="changeShop()" class="shop-select">
        <el-option v-for="item in shopList" :key="item.shop_id" :label="item.shop_name" :value="item.shop_id"></el-option>
      </el-select>
    </el-form-item>
  </div>
</template>

<script setup>
import { useSessionStore } from "@/stores/auth/session";
import { useCompanyStore } from "@/stores/master/company";
import { useShopStore } from "@/stores/master/shop";
import { storeToRefs } from "pinia";
import { reactive, ref, defineEmits, defineProps, onMounted } from "vue";
import { onBeforeRouteLeave } from "vue-router";

const props = defineProps({
  isShowShop: { type: Boolean, required: false, default: true },
  isNoneShop: { type: Boolean, required: false, default: false },
  // 非アクティブ版会社名、店舗名ドロップダウンリスト表示切替用プロパティ
  isSelectableShop: { type: Boolean, required: false, default: true },
});

const sessionStore = useSessionStore();
const { getUserId, getUserCategory, getLoginShopId } = storeToRefs(sessionStore);
const companyStore = useCompanyStore();
const { getCompanyAll } = storeToRefs(companyStore);
const shopStore = useShopStore();
const { getShopList } = storeToRefs(shopStore);

const userId = getUserId.value;
const userCategory = getUserCategory.value;
const userShopId = getLoginShopId.value;

const showShop = ref(props.isShowShop);
const noneShop = ref(props.isNoneShop);
// 非アクティブ版会社名、店舗名ドロップダウンリスト表示切替用フラグ
const selectableShop = ref(props.isSelectableShop);
const nextFromNoneShop = ref(false);

const emit = defineEmits(["changeCompany", "changeShop", "mounted"]);

const select_company = ref({
  company_id: '',
});
const select_shop = ref({
  shop_id: '',
  use_cash_register: sessionStore.use_cash_register,
});

const companyList = reactive([]);
const shopList = reactive([]);

//会社リストをreactive変数「companyList」に格納
const searchCompanyList =  async () => {
  // 全社検索
  await companyStore.searchListCompany();
  companyList.splice(0, companyList.length);
  // 以下汎用処理_店舗なし会社検索
  await companyStore.searchListShopNoneCompany();

// 全社と店舗なし会社をぶつけて店舗あり会社の配列を作成する
  const filteredArray =  getCompanyAll.value.filter(item1 => {
    return !companyStore.shopNoneCompany.some(item2 => item2.company_id === item1.company_id);
  });
  // 店舗マスタ画面専用
  if(noneShop.value && !nextFromNoneShop.value){
    // 店舗なし会社を含む会社リストをcompanyListにassignしメソッドから抜ける
    return Object.assign(companyList, getCompanyAll.value);
  }

  // セッションの会社IDが店舗なし会社の場合は他の会社を入れる
  await companyStore.shopNoneCompany.forEach(element => {
    // 選択されている会社を変更
    if(sessionStore.selectedCompanyId === element.company_id){
      select_company.value.company_id = filteredArray[0].company_id
      sessionStore.$patch({
        selectedCompanyId: filteredArray[0].company_id,
      });
    }
  });
  // 店舗なし会社を含まない会社リストをcompanyListにassign
  Object.assign(companyList, filteredArray);
}

//店舗リストをreactive変数「shopList」に格納
const searchShopList = async () => {
  await shopStore.searchShopListByCompany(sessionStore.selectedCompanyId);
  shopList.splice(0, shopList.length);
  if (getShopList.value.length != 0) {
    const notExist = sessionStore.selectedShopId == "" || getShopList.value.find((shop) => shop.shop_id == sessionStore.selectedShopId) === void 0;
    // 店舗なし会社選択時、店舗画面から別画面へ遷移した場合、もしくはselectedShopIdの店舗が存在しない場合
    if(nextFromNoneShop.value || notExist){
      sessionStore.$patch({
        selectedShopId: getShopList.value[0].shop_id,
        use_cash_register: getShopList.value[0].use_cash_register,
      });
    }
  }
  await Object.assign(shopList, getShopList.value);
}
//店舗責任者の場合、所属店舗でフィルターをかける
const filterShopListByUser = async () => {
  const filterList = await getShopList.value.filter((item) => {
    return userShopId.includes(item.shop_id);
  });
  shopList.splice(0, shopList.length);
  Object.assign(shopList, filterList);
};

onMounted(async () => {

  if (userCategory == 0) {
    //ユーザ種別がシャイン総研の場合、会社、店舗の情報を取得する
    await searchCompanyList();
    await searchShopList();
  } else if (userCategory == 1 || userCategory == 2) {
    //ユーザ種別が会社責任者または店舗責任者の場合、店舗の情報を取得する
    await searchShopList();
  }
  /**
  * ユーザ種別ごとの追加処理
  */

  if (userCategory == 2) {
  //ユーザ種別が店舗責任者の場合、filterShopListByUserを実行
  await filterShopListByUser();
  }

  // 店舗リストの選択した値を入れるref変数 select_shop.shop_idにセッション上の選択済み店舗IDを代入する
  select_shop.value.shop_id = sessionStore.selectedShopId;
  // 会社リストの選択した値を入れるref変数 select_company.company_idにセッション上の選択済み会社IDを代入する
  select_company.value.company_id=sessionStore.selectedCompanyId;
  // product-list用（onMountedの代わりにメソッド発火）
  emit("mounted");
});

/**
 * シャイン総研利用
 * 会社・店舗選択DBのUPDATE
 * 店舗プルダウンの選択項目の絞り込み
 */
const changeCompany = async () => {
  // セッションに保存
  sessionStore.$patch({
    selectedCompanyId: select_company.value.company_id,
  });
  // 店舗プルダウン項目のshopListをstoreから取得
  await searchShopList();
  if(shopList.length == 0 || shopList.length == null){
      select_shop.value.shop_id = '';
      select_shop.value.use_cash_register = '';
      sessionStore.$patch({
        selectedShopId: '',
        use_cash_register: '',
      });
  }else{
    select_shop.value.shop_id = shopList[0].shop_id;
    select_shop.value.use_cash_register = shopList[0].use_cash_register;
    sessionStore.$patch({
      selectedShopId: select_shop.value.shop_id,
      use_cash_register: select_shop.value.use_cash_register,
    });
  }
  // APIへ更新リクエスト
  await updateSelected();
  emit("changeCompany");
  
};

/**
 * 全ユーザ利用
 * 会社・店舗選択DBのUPDATE
 */
const changeShop = async () => {
  const selectedShopId = select_shop.value.shop_id;
  const selectedShop = shopList.find((item) => item.shop_id === selectedShopId);
  select_shop.value.use_cash_register = selectedShop.use_cash_register;
  // セッションに保存
  sessionStore.$patch({
    selectedShopId: select_shop.value.shop_id,
    use_cash_register: select_shop.value.use_cash_register,
  });
  // APIへ更新リクエスト
  await updateSelected();
  emit("changeShop");
};

const updateSelected = async () => {
  const payload = {
    line_id: userId,
    company_id: sessionStore.selectedCompanyId,
    shop_id: sessionStore.selectedShopId,
  };
  await sessionStore.updateSelected(payload);
};

onBeforeRouteLeave(async (to, from, next) => {
  
  if(to.path === '/master-shop-form'){
    return next();
  }

  if(select_shop.value.shop_id === '' && noneShop.value){
    nextFromNoneShop.value = true;
    await searchCompanyList();
    await searchShopList();
  }
  next();
})
</script>
<style lang="scss">
// .test {
//   position: absolute !important;
//   color: red;
//   background-color: white;
// }
</style>
<style lang="scss" scoped>
.c-select {
  display: flex;

  .company-select {
    width: 350px;
  }

  .shop-select {
    width: 220px;
  }
}

:deep(.el-form-item) {
  margin: 0;
}

:deep(.el-form-item__label) {
  line-height: 40px;
  font-size: 16px;
}

:deep(.el-input__inner::placeholder){
  color: transparent;
}

:deep(.el-select .el-input__wrapper) {
  border-radius: 5px;
  box-shadow: 0 0 0 2px var(--systemColorMain) inset;
  box-sizing: border-box;
  margin-right: 24px;
}
</style>