<template>
  <el-form-item :label="$t('COMMON.COMPONENT.SELECT_COMPANY.LABEL')">
    <el-select v-model="select_company.company_id" filterable :placeholder="$t('COMMON.COMPONENT.SELECT_COMPANY.PLACEHOLDER')"
      value-key="companyName" @change="changedCompany()">
      <el-option v-for="item in companyList" :key="item.company_id" :label="item.company_name" :value="item.company_id" />
    </el-select>
  </el-form-item>
  <div v-if="showShop === true">
    <ChildComponent
      ref="childComponent">
    </ChildComponent>
  </div>
</template>

<script setup>
import { useCompanyStore } from "@/stores/master/company";
import { storeToRefs } from "pinia";
import { onMounted, reactive, ref, defineProps } from "vue";
import ChildComponent from "./CommonSelectShop.vue";
import { useSessionStore } from "@/stores/auth/session";

const sessionStore = useSessionStore();

const childComponent = ref();

const select_company = ref({
  company_id: sessionStore.selectedCompanyId,
});

const companyList = reactive([]);

const companyStore = useCompanyStore();
const { getCompanyAll } = storeToRefs(companyStore);

const searchList = async () => {
  await companyStore.searchListCompany();
};

const setShop = async () => {
  if (showShop.value) {
    await childComponent.value.setShopList(select_company.value.company_id);
  }
}

const changedCompany = () => {
  sessionStore.$patch({
    selectedCompanyId: select_company.value.company_id,
    selectedShopId: ""
  })
  setShop();
  if (showShop.value) {
    childComponent.value.setShopId("");
  }
}

onMounted(async () => {
  await searchList();
  Object.assign(companyList, getCompanyAll.value);
  setShop();
});

//shop非表示設定
const props = defineProps({
  isShowShop: { type: Boolean, required: true }
})
const showShop = ref(props.isShowShop);
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: unset;
  margin-right: 64px;
}

:deep(.el-form-item__label) {
  line-height: 40px;
  font-size: 16px;
}

:deep(.el-select .el-input__wrapper) {
  width: 375px;
  height: 40px;
  border-radius: 5px;
  box-shadow: 0 0 0 2px var(--systemColorMain) inset;
  box-sizing: border-box;
  margin-right: 24px;
}

:deep(.shop_select) {
  width: 213px;
}
</style>
