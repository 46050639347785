import { createRouter, createWebHistory } from "vue-router"
import BaseLayout from '@/components/layouts/main-layout/BaseLayout.vue';
import DailyLayout from '@/components/layouts/main-layout/DailyLayout.vue';
import AnnualLayout from '@/components/layouts/main-layout/AnnualLayout.vue';
import ProductLayout from '@/components/layouts/main-layout/ProductLayout.vue';
import MasterLayout from '@/components/layouts/main-layout/MasterLayout.vue';
import { useSessionStore } from "@/stores/auth/session";

const routes = [
  {
    path: "/login",
    name: 'login',
    component: () => import('@/views/web-login.vue')
  },
  {
    path: "/send-email",
    name: 'send-email',
    component: () => import('@/views/web-send-email.vue')
  },
  {
    path: "/reset-password/:token",
    name: 'reset-password',
    component: () => import('@/views/web-reset-password.vue'),
  },
  {
    path: "/reset-password-login",
    name: 'reset-password-login',
    component: () => import('@/views/web-reset-password-login.vue'),
  },
  {
    path: "/",
    component: BaseLayout,
    children: [
      {
        path: '/',
        name: 'dashboard',
        component: () => import('@/views/dashboard/web-dashboard.vue')
      }]
  },
  {
    path: '/daily-sales-target',
    component: DailyLayout,
    children: [
      {
        path: '',
        name: 'daily-sales-target',
        component: () => import('@/views/daily/sales-target.vue')
      },
      {
        path: '/daily-mq-report',
        name: 'daily-mq-report',
        component: () => import('@/views/daily/mq-report.vue')
      },
      {
        path: '/daily-sales-result',
        name: 'daily-sales-result',
        component: () => import('@/views/daily/sales-result.vue')
      }
    ]
  },
  {
    path: '/annual-sales-target',
    component: AnnualLayout,
    children: [
      {
        path: '',
        name: 'annual-sales-target',
        component: () => import('@/views/annual/sales-target.vue')
      },
      {
        path: '/annual-mq-report',
        name: 'annual-mq-report',
        component: () => import('@/views/annual/annual-mq-report.vue')
      },
      {
        path: '/annual-sales-product',
        name: 'annual-sales-product',
        component: () => import('@/views/annual/sales-product.vue')
      },
    ]
  },
  {
    path: '/product-list',
    component: ProductLayout,
    children: [
      {
        path: '',
        name: 'product',
        component: () => import('@/views/product/product-list.vue')
      },
      {
        path: '/product-material-list',
        name: 'material',
        component: () => import('@/views/product/material-list.vue')
      },
      {
        path: '/product-recipe-list',
        name: 'recipe',
        component: () => import('@/views/product/recipe-list.vue')
      }
    ]
  },
  {
    path: '/master-company-list',
    component: MasterLayout,
    children: [
      {
        path: '',
        name: 'company',
        component: () => import('@/views/master/company/list-company.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/master-company-form',
        name: 'company-form',
        component: () => import('@/views/master/company/form-company.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/master-user-list',
        name: 'user',
        component: () => import('@/views/master/user/list-user.vue')
      },
      {
        path: '/master-user-form',
        name: 'user-form',
        component: () => import('@/views/master/user/form-user.vue')
      },
      {
        path: '/master-shop-list',
        name: 'shop',
        component: () => import('@/views/master/shop/list-shop.vue')
      },
      {
        path: '/master-shop-form',
        name: 'shop-form',
        component: () => import('@/views/master/shop/form-shop.vue')
      },
      {
        path: '/master-holiday-list',
        name: 'holiday',
        component: () => import('@/views/master/holiday/list-holiday.vue'),
        meta: { requiresAuth: true }
      }
    ]
  }
]


const router = createRouter({
  history: createWebHistory(),
  routes,
})

const notRequireAuth = ['login' , 'send-email' , 'reset-password'];

// ルーティングの前処理、store内のユーザー情報のチェック
router.beforeEach((to) => {
  const sessionStore = useSessionStore();
  // ログイン済みでない場合はログイン画面へリダイレクト
  if (!notRequireAuth.includes(to.name) && sessionStore.isAuthenticated !== true) {
    return { name: 'login' };
  } else {
    // シャイン総研ユーザでない場合は別画面(ユーザ一覧)へリダイレクト
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (sessionStore.getUserCategory != 0) {
        return { name: 'user' };
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
})

export default router;