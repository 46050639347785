import { createI18n } from "vue-i18n";

const messages = {
  ja: {
    // 共通
    COMMON: {
      DIAPLAY_SIZE: "表示サイズ",
      DISPLAY_NAME: "表示名",
      START_TIME: "0:00",
      STEP: "00:15",
      END_TIME: "24:00",
      TIME_FORMAT: "HH:mm:ss",
      NOTICE_MESSAGE: "事業再構築 機-1 ※事業再構築補助金事業以外での使用禁止",
      // ボタン系
      BUTTON: {
        SEARCH: "検索",
        ADD: "追加",
        ADD_02: "追加する", // list-shop.vue で使用
        EDIT: "編集",
        DELETE: "削除",
        DELETE_02: "削除する",
        DISCARD: "破棄",
        DISCARD_02: "破棄する",
        TO_BACK: "戻る",
        INSERT: "登録",
        INSERT_02: "登録する", // form-shop.vue で利用
        UPDATE: "更新",
        UPDATE_02: "更新する", // form-user.vue で使用
        CHANGE: "変更する",
        ESTABLISH: "設定",
        CONFIRM: "確認",
        SAVE: "保存する",
        SEND: "送信する",
        REFLECT: "反映",
        REFLECT_02: "反映する",
        CONTINUE: "続行する",
        CANCEL: "キャンセル",
        RECOUNT: "再集計する",
        CSV_EXPORT: "CSVを出力する"
      },
      // プレースフォルダ系
      PLACEHOLDER: {
        SEARCH: "検索",
        TIME_SELECT_24HOURS_NOTATION: "24時間表記",
        ENTER_NAME: "名前を入力",
        SELECT_COMPANY: "会社名を選択",
        SELECT_SHOP: "店舗を選択",
        ENTER_MAILADDRESS: "メールアドレスを入力",
        USER_CATEGORY: "ユーザ種別",
        SEARCH_KEYWORD: "キーワード検索",
        YEAR_MONTH: "年/月",
      },
      // メッセージ系
      MESSAGE: {
        REQUIRE: "{ name }を入力してください。",
        REQUIRE_SELECT: "{ name }を選択してください。",
        CAN_BE_INPUT: "〇白色セルのみ入力可",
        // ナビゲーションガード
        CONFIRM_PAGE_LEAVE: "変更内容を保存して移動しますか？",
        // Login
        ERROR_ID_OR_PASS: "ユーザIDまたはパスワードが間違っています",
        // パスワードリセット
        DESCRIPTION_SENDMAIL: "システムに登録しているメールアドレスを入力してください。パスワード再設定のリンクをメールでお送りします。メールアドレスをお忘れの方は、シャイン総研の担当者までご連絡ください。",
        COMPLETED_SENDMAIL: "メールアドレスにメールを送信しました。制限時間内にリンク先よりお手続きください。メールが届かない場合はシャイン総研の担当者までご連絡ください。",
        PROMPT_INPUT: "メールアドレスを入力してください。",
        NOT_REGIST_ADDRESS: "このメールアドレスは登録されていません",
        REGEX_PASSWORD: "半角英数字記号で6文字以上を入力してください",
        NOT_CORRECT_PASSWORD: "パスワードが一致しません",
        FAILED_SENDMAIL: "メールの送信に失敗しました。",
        FAILED_RESETPASSWORD: "パスワードの再設定に失敗しました。",
        FAILED_CHECK_URL: "URLの検証に失敗しました。",
        INVALID_URL: "無効なURLです",
        // 日次決算
        SAVE_SPREADSHEET: "{ name }を保存しますか？",
        CONTINUE_OR_CANCEL: "入力値がクリアされます。続行しますか？",
        CHECK_DIGIT_CELL_COLOR: " 入力値の桁数が無効です。\n 黄色のセルの入力値を修正してください。",
        CHECK_DIGIT: "\n「F1(社員)」「F2(その他経費)」「PQ(売上金額)」「VQ(売上原価)」 は \n 7桁以下で入力してください。 \n 「時間」 は3桁以下で入力してください。 \n 「時給」「Q(客数)」 は5桁以下で入力してください。",
        CHECK_DIGIT_SALES_TARGET: "\n「F1(社員)」「F1(アルバイト)」「F2(その他経費)」「PQ(売上金額)」「VQ(売上原価)」 は \n 7桁以下で入力してください。",
        UNSELECTABLE_DROPDOWN_LIST: "「会社名」「店舗名」「年月」を切り替えるには、変更内容を保存、またはキャンセルしてください。",
        UNSELECTABLE_DROPDOWN_LIST_YEAR: "「会社名」「店舗名」「年」を切り替えるには、変更内容を保存、またはキャンセルしてください。",
        // マスター 会社
        DELETE_COMPANY_INFORMATION: "会社情報を削除しますか？",
        ENTER_NAME: "名前を入力してください",
        PLEASE_SELECT: "選択してください",
        ENTER_COMPANY_NAME: "会社名を入力してください",
        ENTER_LINE_DELIVARY_TIME: "LINE配信時刻を入力してください",
        ENTER_CLOSING_MONTH: "決算月を入力してください",
        DELETE_SHOP_INFORMATION: "店舗情報を削除しますか？",
        REMOVE_USER_MESSAGE: "ユーザ情報を削除しますか？",
        REMOVE_PRODUCT_MESSAGE: "商品情報を削除しますか？",
        REMOVE_PRODUCTSYNC_MESSAGE: "商品情報を取り込みますか？",
        REMOVE_PRODUCTAPPLY_MESSAGE: "レジ原価同期しますか？",

        REMOVE_PRODUCTSYNCSU_MESSAGE: "商品情報取り込みに成功しました。",
        REMOVE_PRODUCTAPPLYFA_MESSAGE: "レジ原価同期に失敗しました。",
        REMOVE_PRODUCTAPPLYFA_MESSAGE1: "再度実行してください。",
        REMOVE_PRODUCTSYNCFA_MESSAGE: "商品情報取り込みに失敗しました。",
        REMOVE_PRODUCTSYNCFA_MESSAGE1: "再度実行してください。",
        REMOVE_PRODUCTAPPLYSU_MESSAGE: "レジ原価同期を完了しました。",
        ENCOURAGE_UPDATE: "変更内容を反映してください。",
        INCORRECT_INPUT: "正しい値を入力してください。",
        INCORRECT_INPUT_MATERIAL: "登録されていない材料名が入力されました。",
        CANNOT_USE: "利用が許可されていません。",
        ENCOURAGE_REGISTER_SMART_REG: "スマレジを登録してください。",

        // マスター 祝日
        UPDATE_CHANGES: "変更内容を反映しますか？",
        DISCARD_CHANGES: "変更内容を破棄しますか？",
        UPDATE_CHANGES_AND_TRANSITION: "変更内容を反映して移動しますか？",

        // マスター ユーザ
        ENTER_USERNAME: "氏名を入力してください",
        SELECT_SHOP: "担当店舗を選択してください",
        ENTER_MAILADDRESS: "メールアドレスを入力してください",
        SELECT_USERCATEGORY: "ユーザ種別を選択してください",
        MAIL_REGULAR_EXPRESSION: "メールアドレスを正しく入力してください",

        // 原価 材料
        UNSELECTABLE_DROPDOWN_LIST_OF_MATERIAL_LIST: '「会社名」「店舗名」を切り替えるには、変更内容を保存、またはキャンセルしてください。',
        CHECK_RECIPE_COUNT: "原価設定に登録されている材料があります。削除する前に原価設定画面から登録を解除してください。",
        BLANK_MATERIAL_NAME: "材料名を入力してください。",
        DUPLICATION_MATERIAL_ID: "同じ材料が複数選択されています。",
        MAX_MATERIAL_PRICE: '10,000,000円以上の金額は設定できません',
        MAX_QUANTITY: '10,000以上の値は入力できません。',
        MAX_PRODUCT_COST: '商品のV(原価)は、100,000,000円以上設定できません',
        MAX_WIP_COST: '仕掛品のV(原価)は、10,000,000円以上設定できません',
        NOT_CHANGE_ZERO_MATERIALPRICE: '商品・仕掛品の原価に登録されている材料の仕入単価は、0円に変更することはできません。'
      },
      // チェックボックス系
      CHECK_BOX: {
        VALID: "有効",
        INVALID: "無効",
        POSSIBLE: "可",
        NOT_POSSIBLE: "不可",
        UTILIZE: "利用する",
      },
      // 共通コンポーネント
      COMPONENT: {
        SELECT_COMPANY: {
          LABEL: "会社名",
          PLACEHOLDER: "会社名を選択",
        },
        SELECT_SHOP: {
          LABEL: "店舗名",
          PLACEHOLDER: "店舗名を選択",
        },
      },
    },

    // MQ用語
    MQ: {
      Q: "Q(客数)",
      F1_EMP: "F1(社員)",
      F1_PART: "F1(アルバイト)",
      F2: "F2(その他経費)",
      PQ: "PQ(売上金額)",
      VQ: "VQ(売上原価)",
      MQ: "MQ(粗利)",
      G: "G(利益)",
      SALE_1: "売上1",
      SALE_3: "売上2",
      SALE_2: "売上3",
      F: "F",
      P: "P(単価)",
      V: "V(原価)",
      COST_RATE: "原価率",

      // MQレポート
      COMPANY: "会社",
      SHOP: "店舗",
      YEAR_MONTH: "年月",
      ADD: "追加する",
      SIZE: "表示サイズ",
      COPY: "コピー",
      ADD_TO_DASH: "ダッシュボードに追加",
      DOWNLOAD_CSV: "CSVダウンロード",
    },

    // ヘッダー・メニュー項目
    MENU: {
      MAIN: {
        DASHBOARD: "ダッシュボード",
        DAILY: "日次決算",
        ANNUAL: "年間MQ",
        COST_MANAGEMENT: "原価管理",
        MASTER: "マスタ管理",
      },
      DAILY: {
        SALES_TARGET: "目標設定",
        MQ_REPORT: "MQレポート",
        SALES_RESULT: "実績修正"
      },
      ANNUAL: {
        SALES_TARGET: "予算・実績",
        MQ_REPORT: "MQレポート",
        MONTHLY_PRODUCT: "商品別MQ"
      },
      COST_MANAGEMENT: {
        PRODUCT: "商品",
        MATERIAL: "材料",
      },
      MASTER: {
        COMPANY: "会社",
        USER: "ユーザ",
        SHOP: "事業所・店舗",
        HOLIDAY: "祝日"
      },
      PASSWORD_CHANGE: "パスワード変更",
      LOGOUT: "ログアウト"
    },

    // ログイン画面
    LOGIN: {
      MQCHECKER: "MQチェッカー",
      MAILADDRESS: "メールアドレス",
      PASSWORD: "パスワード",
      SAVE_MAILADDRESS: "メールアドレスを保存する",
      LOGIN: "ログイン",
      FORGOT_PASSWORD: "パスワードをお忘れの方",
    },

    // パスワード再設定画面
    RESET_PASS: {
      TITLE: "パスワードリセット",
      SENTENCE:
        "システムに登録しているメールアドレスを入力してください。<br /> パスワード再設定のリンクをメールでお送りします。 <br /> メールアドレスをお忘れの方は、シャイン総研の担当者まで <br /> ご連絡ください。",
      MAILADDRESS: "メールアドレス",
      TO_BACK: "ログイン画面に戻る",
      NEW_PASS: "新しいパスワード",
      NEW_PASS_CONFIRM: "新しいパスワード(確認)"
    },

    // ダッシュボード
    DASHBOARD: {},

    // 日次決算
    DAILY: {
      MQ_SPREAD: {
        DATE: "日付",
        DAY_OF_WEEK: "曜日",
        HOLIDAY: "休日",
        TOTAL: "総合計",
        REGI: "レジ",
      },
      SALES_TARGET: {
        INPUT_REGULAR_HOLIDAY: "定休日を反映しますか？",
        INPUT_TARGET: "{ name }の目標を反映しますか？",
        CHECK_HOLIDAY: "選択した項目を休日に設定しますか？",
        CHECK_HOLIDAY_SUB: "休日に設定した場合、各目標の金額が0円にリセットされます。",
        INPUT_FORM: {
          SET_HOLIDAY: "定休日設定",
          MON: "月",
          TUE: "火",
          WED: "水",
          THU: "木",
          FRI: "金",
          SAT: "土",
          SUN: "日",
          REGI: "レジ",
          F: "F",
          H: "h",
          PARCENTAGE: "%",
          SET_FIXED_COST: "固定費設定",
          F1_PART_WAGE: "F1(アルバイト)時給",
          WAGE_UNIT: "円/時間",
          F2_OTHER_EXPENSES: "F2(その他経費)",
          YEN: "円",
          SALES: "売上",
          VARIABLE: "原価",
          RESULT: "前月実績",
          TARGET: "当月目標",
          PARTTIME_TIME: "時間",
          GOAL_SALES: "目標売上",
        },
      },
      SALES_RESULT: {
        TOTAL_WORKING_HOURS: "時間",
        F1_HOURLY_PAY: "時給",
        F1_PART_TIME_AMOUNT: "金額",
      },
    },

    // 年間MQ
    ANNUAL_MQ: {
      // サブタイトル
      BUDGET_ACHIEVEMENTS: "予算・実績",
      MQ_REPORT: "MQレポート",
      MQ_BY_PRODUCT: "商品別MQ",

      COMPNAY_NAME: "会社名",
      CLOSING_MONTH: "決算月",
      ALL_SHOP: "全店舗",
      PREDICTION: "予想",
      BUDGET: "予算",
      ACHIEVEMENTS: "実績",
      COLON: ":",
      TITLE_PQ: "PQ(売上金額)",
      TITLE_VQ: "VQ(売上原価)",
      TITLE_MQ: "MQ(粗利)",
      TITLE_F: "F(固定費)",
      TITLE_G: "G(利益)",
      PARENTHESES_LEFT: "(",
      PARENTHESES_RIGHT: ")",
      DISPLAY_PERIOD: "表示期間",

      // 商品別MQ
      SHOP_NAME: "店舗名",
      YEAR: "年",
      MONTH: "月",
      PRODUCT_CODE: "商品コード",
      PRODUCT_NAME: "商品名",

      // MQレポート
      COMPANY: "会社",
      SHOP: "店舗",
      ADD: "追加する",
      SIZE: "表示サイズ",
      COPY: "コピー",
      DOWNLOAD_CSV: "CSVダウンロード",
      MONTH_SPACE: "月　",
    },

    // 原価管理
    COST_MANAGEMENT: {
      // サブタイトル
      PRODUCT: "商品",
      MATERIAL: "材料",
      COST: "原価",

      COMPANY_NAME: "会社名",
      SHOP_NAME: "店舗名",
      // 商品
      PRODUCT_CODE: "商品コード",
      PRODUCT_NAME: "商品名",
      BTN_GET_PRODUCT_INFO: "商品情報取込",
      BTN_REGISTER_SYNCHRO: "レジ原価同期",
      BTN_SET_COST: "原価設定",
      P: "P(単価)",
      V: "V(原価)",
      COST_RATE: "原価率",
      // 材料
      MATERIAL_ID: "材料ID",
      MATERIAL_NAME: "材料名",
      PURCHASE_PRICE: "仕入単価",
      // 原価設定
      QUANTITY: "数量",
      MTERIAL_PRICE: "材料原価",
      DELETE: "削除",
    },

    // マスタ管理
    MASTER: {
      COMPANY: {
        // LIST / FORM
        COMPANY_NAME: "会社名",
        LINE_DELIVARY_TIME: "LINE配信時刻",
        MQ_CHART_DELIVARY_TIME: "MQグラフ配信時間",
        CLOSING_MONTH: "決算月",
      },

      USER: {
        // LIST
        USER_NAME: "利用者名",
        COMPANY_NAME: "会社名",
        SHOP: "所属店舗",
        MAIL_ADDRESS: "ユーザID(メールアドレス)",
        USER_TYPE: "ユーザ種別",
        // FORM
        NAME: "氏名",
        SHOP_IN_CHARGE: "担当店舗",
        LINE_NOTIFICATION: "LINE通知",
        ENTER_SALES: "売上入力",
        ALLOW_USE_MQCHECKER: "MQチェッカー利用を許可",
      },
      SHOP: {
        // LIST
        COMPANY: "会社",
        SHOP: "店舗",
        TYPE_OF_CASH_REGISTER: "レジ種類",
        // Form
        SHOP_COPY: "店舗コピー",
        COMPANY_NAME: "会社名",
        SHOP_NAME: "事業所・店舗名",
        CLOSING_TIME: "締め時間",
        SMART_REG_ID: "スマレジ契約ID",
        LINE_URL: "LINE URL",
        LINE: "LINE",
        // QR code
        QR_CODE: "QR",
      },
      HOLIDAY: {
        DAY: "日付",
        PUBLIC_HOLIDAY: "名称",
        HIDE_HOLIDAY: "去年以前の祝日を非表示",
      },
    },
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "ja",
  messages,
});

export default i18n;
