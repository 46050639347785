/**
 * click event to download file
 * 
 * @param {blob|string} blob 
 * @param {string} filename 
 */
export const download = (blob, filename) => {
  const el = document.createElement("a");
  if (typeof blob === "string") {
    el.target = "_blank";
    el.href = blob;
  } else {
    el.href = URL.createObjectURL(blob);
  }
  el.download = filename;
  el.click();
  if (typeof blob !== "string") {
    URL.revokeObjectURL(el.href);
  }
};

/**
 * deep copy of object
 * 
 * @param {object} obj 
 * @returns 
 */
export const objDeepCopy = (obj) => {
  if(obj === null || obj === undefined) {
    return {}
  }
  const copy = Object.create(Object.getPrototypeOf(obj))
 
  Object.getOwnPropertyNames(obj).forEach((propKey) => {
    const desc = Object.getOwnPropertyDescriptor(obj, propKey)
    Object.defineProperty(copy, propKey, desc);
    if(typeof desc.value === "object") {
      copy[propKey] = objDeepCopy(obj[propKey])
    }
  })
  return copy
}

/**
 * get start index by pages 
 * @param {int} currentPage 
 * @param {int} prePage 
 * @returns 
 */
export const getStartIdxByPages = (currentPage, prePage) => {
  return (currentPage - 1) * prePage;
}