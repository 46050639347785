<template>
  <el-form-item :label="$t('COMMON.COMPONENT.SELECT_SHOP.LABEL')">
    <el-select
      class="shop_select"
      v-model="select_shop.shop_id"
      :placeholder="$t('COMMON.COMPONENT.SELECT_SHOP.PLACEHOLDER')"
      value-key="shopName"
      @change="changedShop()"
    >
      <el-option
        v-for="item in shopList"
        :key="item.shop_id"
        :label="item.shop_name"
        :value="item.shop_id"
      />
    </el-select>
  </el-form-item>
</template>

<script setup>
import { useShopStore } from "@/stores/master/shop";
import { storeToRefs } from "pinia";
import { reactive, ref, defineExpose } from "vue";
import { useSessionStore } from "@/stores/auth/session";

const sessionStore = useSessionStore();
const shopStore = useShopStore();
const { getShopAll } = storeToRefs(shopStore);
const shopList = reactive([]);

const select_shop = ref({
  shop_id: sessionStore.selectedShopId,
});

const searchListShop = async (company_id) => {
  await shopStore.searchShopListByCompany(company_id);
};

const changedShop = async () => {
  sessionStore.$patch({
    selectedShopId: select_shop.value.shop_id,
  })
}

//親コンポーネントで使用するメソッド
const setShopList = async (company_id) => {
  await searchListShop(company_id);
  shopList.splice(0, shopList.length);
  if (getShopAll.value.length != 0) {
    Object.assign(shopList, getShopAll.value);
  }
};

const setShopId = (shopId) => {
  select_shop.value.shop_id = shopId;
}

defineExpose({
  setShopList: setShopList,
  setShopId: setShopId
});

</script>