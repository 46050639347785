import ApiRequest from "@/common/axios";

export const URL_COMP_LIST = "api/master-company"
// user api url
export const URL_USER_LIST = "api/master-user"
// shop api url
export const URL_SHOP_LIST = "api/master-shop"
// holiday api url
export const URL_HOLI_LIST = "api/master-holiday"
// product api url
export const URL_PORDUCT_LIST = "api/product"
// product api url
export const URL_COST_PORDUCT_LIST = "api/cost-product"

// material api url
export const URL_COST_MATERIAL_LIST = "api/material"

// company module
export const apiGetCompanys   = (payload) => ApiRequest.get(URL_COMP_LIST, {params: payload})
export const apiGetCompany    = (id) => ApiRequest.get(URL_COMP_LIST+ "/" +id)
export const apiPostCompany   = (payload) => ApiRequest.post(URL_COMP_LIST, payload)
export const apiPutCompany    = (id, payload) => ApiRequest.put(URL_COMP_LIST + "/" + id, payload)
export const apiDeleteCompany = (id) => ApiRequest.delete(URL_COMP_LIST + "/" + id)

// user module
export const apiGetUsers   = (payload) => ApiRequest.get(URL_USER_LIST, {params: payload});
export const apiGetUser    = (id) => ApiRequest.get(URL_USER_LIST + "/" + id);
export const apiPostUser   = (payload) => ApiRequest.post(URL_USER_LIST, payload);
export const apiPutUser    = (id, payload) => ApiRequest.put(URL_USER_LIST + "/" + id, payload);
export const apiDeleteUser = (id)   => ApiRequest.delete(URL_USER_LIST + "/" + id);

// shop module
export const apiGetShops   = (payload) => ApiRequest.get(URL_SHOP_LIST, {params: payload});
export const apiGetShop    = (id) => ApiRequest.get(URL_SHOP_LIST + "/" + id);
export const apiPostShop   = (payload) => ApiRequest.post(URL_SHOP_LIST, payload);
export const apiPutShop    = (id, payload) => ApiRequest.put(URL_SHOP_LIST + "/" + id, payload);
export const apiDeleteShop = (id)   => ApiRequest.delete(URL_SHOP_LIST + "/" + id);

// holiday module
export const apiGetHolidays   = (payload) => ApiRequest.get(URL_HOLI_LIST, {params: payload});
export const apiGetHoliday    = (id) => ApiRequest.get(URL_HOLI_LIST + "/" + id);
export const apiPostHoliday   = (payload) => ApiRequest.post(URL_HOLI_LIST, payload);
export const apiPutHoliday    = (id, payload) => ApiRequest.put(URL_HOLI_LIST + "/" + id, payload);
export const apiDeleteHoliday = (id)   => ApiRequest.delete(URL_HOLI_LIST + "/" + id);
//product module
export const apiGetProducts   = async (payload) => ApiRequest.get(URL_PORDUCT_LIST, {params: payload});
export const apiDeleteProduct = async (id)   => ApiRequest.delete(URL_PORDUCT_LIST + "/" + id);

export const apiPostProduct   = (payload) => ApiRequest.post(URL_COST_PORDUCT_LIST, payload);
export const apiGetProduct    = (payload) => ApiRequest.get(URL_COST_PORDUCT_LIST , {params: payload});

// material module
export const apiGetMaterial    = (id) => ApiRequest.get(URL_COST_MATERIAL_LIST + "/" + id);
export const apiPostMaterial  = (payload) => ApiRequest.post(URL_COST_MATERIAL_LIST, payload);
export const apiPutMaterial    = (id, payload) => ApiRequest.put(URL_COST_MATERIAL_LIST + "/" + id, payload);
export const apiDeleteMaterial = (id)   => ApiRequest.delete(URL_COST_MATERIAL_LIST + "/" + id);
export const apiPostRecipeCount = (payload) => ApiRequest.post(URL_COST_MATERIAL_LIST + "/recipe_count", payload);