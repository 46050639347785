import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import App from './App.vue'
import router from './router/index.js'
import ElementPlus from 'element-plus'
import ja from 'element-plus/es/locale/lang/ja'
import 'element-plus/dist/index.css'
import './assets/css/config.scss'
import './assets/css/layout.scss'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import VueApexCharts from 'vue3-apexcharts'
import "jsuites/dist/jsuites.js"
import "jsuites/dist/jsuites.css"
import "jspreadsheet-ce/dist/jspreadsheet.css"
import jspreadsheet from 'jspreadsheet-ce'
import draggable from "vuedraggable"
import dayjs from "dayjs";
import i18n from './common/text.ts'
import browser_close_alert from './common/browser'
/*独自コンポーネントをグローバルインポート*/
import CButton from "./components/parts/CommonButton.vue"
import CBeforeUnloadDialog from './components/parts/CommonBeforeUnloadDialog.vue'
import CConfirmDialog from './components/parts/CommonConfirmDialog.vue'
import CSelectCompanyAndShop from '@/components/parts/CommonSelectCompanyAndShop'
import CSelectCompany from './components/parts/CommonSelectCompany.vue'
import CSelectShop from './components/parts/CommonSelectShop.vue'
import CSearchInput from './components/parts/CommonSearchInput.vue'
import CLoading from './components/parts/CommonLoading.vue'
import CRequire from './components/parts/CommonRequire.vue'
import VueGtag from "vue-gtag"

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

//本番環境にのみg-tagをセット
if (process.env.NODE_ENV === "production"){
  app
  .use(pinia)
  .use(router)
  .use(ElementPlus, { locale: ja })
  .use(VueApexCharts)
  .use(jspreadsheet)
  .use(dayjs)
  .use(i18n)
  .use(browser_close_alert)
  .use(
    VueGtag,
    {
      config: {id: process.env.VUE_APP_ANALYTICS_TAG}
    },
    router
  )
  .component('apexchart', VueApexCharts)
  .component('VueDraggable', draggable)
  .component('CButton', CButton)
  .component('CBeforeUnloadDialog', CBeforeUnloadDialog)
  .component('CConfirmDialog', CConfirmDialog)
  .component('CSelectCompanyAndShop', CSelectCompanyAndShop)
  .component('CSelectCompany', CSelectCompany)
  .component('CSelectShop', CSelectShop)
  .component('CSearchInput', CSearchInput)
  .component('CComfirmDialog', CConfirmDialog)
  .component('CLoading', CLoading)
  .component('CRequire', CRequire)
  .mount('#app')
} else {
  app
  .use(pinia)
  .use(router)
  .use(ElementPlus, { locale: ja })
  .use(VueApexCharts)
  .use(jspreadsheet)
  .use(dayjs)
  .use(i18n)
  .use(browser_close_alert)
  .component('apexchart', VueApexCharts)
  .component('VueDraggable', draggable)
  .component('CButton', CButton)
  .component('CBeforeUnloadDialog', CBeforeUnloadDialog)
  .component('CConfirmDialog', CConfirmDialog)
  .component('CSelectCompanyAndShop', CSelectCompanyAndShop)
  .component('CSelectCompany', CSelectCompany)
  .component('CSelectShop', CSelectShop)
  .component('CSearchInput', CSearchInput)
  .component('CComfirmDialog', CConfirmDialog)
  .component('CLoading', CLoading)
  .component('CRequire', CRequire)
  .mount('#app')
}