<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
// import { useSessionStore } from "@/stores/auth/session";

const route = useRoute();
const router = useRouter();
const { t } = useI18n({});
// const sessionStore = useSessionStore();

const activeIndex = computed(() => {
  // ルートのパスに基づいてアクティブなメニューアイテムを決定
  if (route.path === "/") {
    return "/dashboard";
  } else if (route.path.includes("/daily")) {
    return "/daily";
  } else if (route.path.includes("/annual")) {
    return "/annual";
  } else if (route.path.includes("/product")) {
    return "/product";
  } else if (route.path.includes("/master")) {
    return "/master";
  } else {
    return "";
  }
});

const toPasswordChange = async () => {
  router.push({ name: "reset-password-login" });
};

const logout = async () => {
  sessionStorage.clear();
  // await destroyToken()
  router.push({ name: "login" });
};
</script>

<template>
  <el-menu
    :default-active="activeIndex"
    mode="horizontal"
    background-color="#0069A3"
    text-color="#fff"
    @select="handleSelect"
    router
    class="menu-area"
  >
    <el-menu-item index="/dashboard" :route="{ name: 'dashboard' }">{{
      t("MENU.MAIN.DASHBOARD")
    }}</el-menu-item>
    <el-menu-item index="/daily" :route="{ name: 'daily-sales-target' }">{{
      t("MENU.MAIN.DAILY")
    }}</el-menu-item>
    <el-menu-item index="/annual" :route="{ name: 'annual-sales-target' }">{{
      t("MENU.MAIN.ANNUAL")
    }}</el-menu-item>
    <el-menu-item index="/product" :route="{ name: 'product' }">{{
      t("MENU.MAIN.COST_MANAGEMENT")
    }}</el-menu-item>
    <el-menu-item index="/master" :route="{ name: 'company' }">{{
      t("MENU.MAIN.MASTER")
    }}</el-menu-item>
    <div class="link-area">
      <CButton
        link
        :text="t('MENU.PASSWORD_CHANGE')"
        :customClass="'link-button'"
        :icon="require('@/assets/img/icon/setting.svg')"
        @clickBtn="toPasswordChange()"
      />
      <CButton
        link
        :text="t('MENU.LOGOUT')"
        :customClass="'link-button'"
        :icon="require('@/assets/img/icon/logout.svg')"
        @clickBtn="logout()"
      />
    </div>
  </el-menu>
</template>

<style lang="scss" scoped>
.el-menu {
  border-bottom-style: none !important;
  padding: 0px 24px;
}

.el-menu--horizontal > .el-menu-item {
  width: 170px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom-style: none !important;
  margin-right: 10px;
}

.el-menu--horizontal > .el-menu-item.is-active {
  color: #333 !important;
  background-color: #fff !important;
}

.el-button.is-link {
  margin-left: unset;
  margin-right: 24px;
  padding: 8px;
  border: none;
  color: #fff;
  font-size: 14px;
}

.menu-area {
  position: relative;
  align-items: center;
}

.link-area {
  position: absolute;
  right: 0px;
}
</style>
