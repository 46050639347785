<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
const route = useRoute();

const activeIndex = computed(() => {
  // ルートのパスに基づいてアクティブなメニューアイテムを決定
  if (route.path === "/daily-sales-target") {
    return "/daily-sales-target";
  } else if (route.path === "/daily-mq-report") {
    return "/daily-mq-report";
  } else if (route.path === "/daily-sales-result") {
    return "/daily-sales-result";
  } else {
    return "";
  }
});
</script>

<template>
  <el-menu :default-active="activeIndex" mode="horizontal" @select="handleSelect" router>
    <el-menu-item index="/daily-sales-target" :route="{ name: 'daily-sales-target' }">{{
      $t("MENU.DAILY.SALES_TARGET")
    }}</el-menu-item>
    <el-menu-item index="/daily-mq-report" :route="{ name: 'daily-mq-report' }">{{
      $t("MENU.DAILY.MQ_REPORT")
    }}</el-menu-item>
    <el-menu-item index="/daily-sales-result" :route="{ name: 'daily-sales-result' }">{{
      $t("MENU.DAILY.SALES_RESULT")
    }}</el-menu-item>
  </el-menu>
</template>

<style lang="scss" scoped>
.el-menu--horizontal {
  margin: 0px;
  padding-top: 10px;
  height: 40px;
}

.el-menu--horizontal.el-menu {
  border-bottom: none;
  box-shadow: 0px -1px #b7b7b721 inset;
}

.el-menu--horizontal > .el-menu-item {
  margin: 0px 80px 0px 0px;
  padding: 4px;
}

.el-menu--horizontal > .el-menu-item.is-active {
  color: #333 !important;
  background-color: #fff !important;
  border-bottom-color: var(--systemColorAccent);
}

:deep(.el-menu-item) {
  &:hover {
    border-bottom-color: var(--systemColorAccent);
  }
}
</style>
