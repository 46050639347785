<template>
  <el-dialog
    v-model="dialogVisible"
    width="30%"
    center
    :before-close="handleCancel"
    :close-on-click-modal="false"
  >
    <el-row justify="center">
      <el-row justify="center" style="margin: auto 48px">
        <p class="message">{{ props.message }}</p>
        <p class="sub-message">{{ props.subMessage }}</p>
      </el-row>
      <el-row justify="center">
        <el-button @click="handleConfirm" class="Dialog__btn--ok">
          {{ props.confirm }}
        </el-button>
        <el-button @click="handleCancel" class="Dialog__btn">
          {{ props.cancel }}
        </el-button>
      </el-row>
    </el-row>
  </el-dialog>
</template>
<script setup>
import { defineProps, defineEmits, ref, watch } from "vue";

const props = defineProps({
  message: { type: String, required: true },
  subMessage: { type: String, required: true },
  isVisible: { type: Boolean, required: true },
  confirm: { type: String, required: true },
  cancel: { type: String, required: true },
});
const emit = defineEmits(["confirm"], ["cancel"]);

const dialogVisible = ref(false);

watch(
  () => props.isVisible,
  (newValue) => {
    dialogVisible.value = newValue;
  }
);

const handleConfirm = () => {
  emit("confirm");
};

const handleCancel = () => {
  emit("cancel");
};
</script>

<style lang="scss" scoped>
.el-row {
  text-align: center;
}

.Dialog__btn {
  display: flex;
  width: 108px;
  height: 26px;
  padding: 2px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  &--ok {
    display: flex;
    width: 108px;
    height: 26px;
    padding: 2px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    color: #fff;
    border-radius: 5px;
    border: none;
    background: var(--main, #0069a3);
  }
}

.Dialog__btn--ok:hover {
  background:var(--hover, #52A4D1);
}

.message {
  font-size: 14px;
}

.sub-message {
  font-size: 12px;
}

.el-overlay-dialog {
  top: 25%;
  bottom: 25%;
}

:deep(.el-dialog) {
  border-radius: 5px;
}

.el-dialog--center .el-dialog__body {
  text-align: initial;
  padding: 20px 24px;
}

.el-dialog__header {
  padding: 8px;
}

.el-dialog__headerbtn {
  top: 0;
  right: 0;
  padding: 0;
  width: 40px;
  height: 44px;
}
</style>
