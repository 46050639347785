<template>
  <el-footer class="notice-message">
    <el-row justify="end">
      {{ t("COMMON.NOTICE_MESSAGE") }}
    </el-row>
  </el-footer>
</template>

<script setup>
import { useI18n } from "vue-i18n";
const { t } = useI18n({});
</script>

<style lang="scss" scoped>
.notice-message {
  font-size: xx-small;
  color: var(--systemColorMain);
}
</style>