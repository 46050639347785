<template>
  <router-view :key="$route.fullPath"></router-view>
</template>

<style>
#app {
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  font-family: "Noto Sans JP Medium";
  font-size: 16px;
}
</style>
