<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useSessionStore } from "@/stores/auth/session";
import { storeToRefs } from "pinia";
const route = useRoute();

const sessionStore = useSessionStore();
const { getUserCategory } = storeToRefs(sessionStore);

const userCategory = getUserCategory.value;

// master router
const activeIndex = computed(() => {
  // ルートのパスに基づいてアクティブなメニューアイテムを決定
  if (route.path.includes("/master-company")) {
    if (userCategory != 0) {
      return "/master-user";
    }
    return "/master-company";
  } else if (route.path.includes("/master-user")) {
    return "/master-user";
  } else if (route.path.includes("/master-shop")) {
    return "/master-shop";
  } else if (route.path.includes("/master-holiday")) {
    return "/master-holiday";
  } else {
    return "";
  }
});
</script>

<template>
  <el-menu :default-active="activeIndex" mode="horizontal" router>
    <el-menu-item
      v-if="userCategory == 0"
      index="/master-company"
      :route="{ name: 'company' }"
      >{{ $t("MENU.MASTER.COMPANY") }}</el-menu-item
    >
    <el-menu-item index="/master-user" :route="{ name: 'user' }">{{
      $t("MENU.MASTER.USER")
    }}</el-menu-item>
    <el-menu-item index="/master-shop" :route="{ name: 'shop' }">{{
      $t("MENU.MASTER.SHOP")
    }}</el-menu-item>
    <el-menu-item
      v-if="userCategory == 0"
      index="/master-holiday"
      :route="{ name: 'holiday' }"
      >{{ $t("MENU.MASTER.HOLIDAY") }}</el-menu-item
    >
  </el-menu>
</template>

<style lang="scss" scoped>
.el-menu--horizontal {
  margin: 0px;
  padding-top: 10px;
  height: 40px;
}

.el-menu--horizontal.el-menu {
  border-bottom: none;
  box-shadow: 0px -1px #b7b7b721 inset;
}

.el-menu--horizontal > .el-menu-item {
  margin: 0px 80px 0px 0px;
  padding: 4px;
}

.el-menu--horizontal > .el-menu-item.is-active {
  color: #333 !important;
  background-color: #fff !important;
  border-bottom-color: var(--systemColorAccent);
}

:deep(.el-menu-item) {
  &:hover {
    border-bottom-color: var(--systemColorAccent);
  }
}
</style>
