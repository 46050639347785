import { defineStore } from "pinia";
import ApiRequest from "@/common/axios";
import {
  apiGetShop,
  apiGetShops,
  apiPostShop,
  apiPutShop,
  apiDeleteShop,
  apiGetCompany,
} from "@/apis/master";
import { objDeepCopy } from "@/composables/util";

const MASTER_SHOP = "api/master-shop";

const LINE_URL = process.env.VUE_APP_LINE_URL;
const QR_PATH = process.env.VUE_APP_QR_PATH;

const defaultShopDetail = {
  shop_id: "",
  shop_name: "",
  company_id: "",
  company_name: "",
  use_cash_register: "",
  regi_token: "",
  regi_shop_id: "",
  deadline_time: "02:00:00",
  line_url: "",
  line_qr: "",
  sales2_usage_flag: "0",
  sales3_usage_flag: "0",
  sales2_name: "",
  sales3_name: "",
  copy_shop_id: "",
  current_hourly_wage: "",
  assumed_cost_price:"",
};

export const useShopStore = defineStore("shop", {
  state: () => ({
    loading: false,
    shopList: [],
    shopDetail: objDeepCopy(defaultShopDetail),
    editShopId: 0,
    isNew: true,
    sales2_usage_flag: true,
    sales3_usage_flag: true,
    beforeCurrentHourlyWage: "0",
    search: {
      query: {
        searchValue: ""
      }
    }
  }),
  getters: {
    getSearch: (state) => state.search,
    getEditShopId: (state) => state.editShopId,
    getIsNew: (state) => state.isNew,
    getSales2UsageFlag: (state) => state.sales2_usage_flag,
    getSales3UsageFlag: (state) => state.sales3_usage_flag,
    getBeforeCurrentHourlyWage: (state) => state.beforeCurrentHourlyWage,
    getShopList: (state) => {
      const shopList = objDeepCopy(state.shopList);
      return shopList;
    },
    getShopAll: (state) => {
      return objDeepCopy(state.shopList);
    },
    getShopDetail: (state) => {
      return state.shopDetail;
    }
  },
  actions: {
    async searchShopList(params) {
      return apiGetShops(params)
        .then((res) => {
          const { success, data } = res.data;
          if (success === true) {
            this.shopList = data;
          }
        })
        .catch((err) => console.log(err));
    },
    async searchShopListByCompany(company_id) {
      const params = {
        search_value: this.getSearch.query.searchValue
      };
      return ApiRequest.get(`${MASTER_SHOP}/list/${company_id}`, params)
        .then((res) => {
          const { success, data } = res.data;
          if ( success === true) {
            this.shopList = data;
          }
        })
        .catch((err) => console.log(err));
    },
    async getShopDetailById() {
        const ret = await apiGetShop(this.getEditShopId)
        const { success, data } = ret.data;
        if(success) {
          this.shopDetail.shop_id = data.shop_id;
          this.shopDetail.shop_name = data.shop_name;
          this.shopDetail.company_id = data.company_id;
          this.shopDetail.company_name = data.company_name;
          this.shopDetail.use_cash_register = data.use_cash_register;
          this.shopDetail.regi_token = data.regi_token;
          this.shopDetail.regi_shop_id = data.regi_shop_id;
          this.shopDetail.deadline_time = data.deadline_time;
          this.shopDetail.sales2_usage_flag = data.sales2_usage_flag;
          this.shopDetail.sales3_usage_flag = data.sales3_usage_flag;
          this.shopDetail.sales2_name = data.sales2_name;
          this.shopDetail.sales3_name = data.sales3_name;
          this.shopDetail.line_url = LINE_URL + data.shop_id;
          this.shopDetail.line_qr = QR_PATH + data.shop_id + ".png";
          this.shopDetail.current_hourly_wage = data.current_hourly_wage;
          this.shopDetail.assumed_cost_price = data.assumed_cost_price;
          this.beforeCurrentHourlyWage = data.current_hourly_wage;
        }
    },
    async getDetailCompanyById(company_id) {
      const ret = await apiGetCompany(company_id);
      
      const{ success, data } = ret.data;
      if(success) {
        this.shopDetail.company_name = data.company_name;
      }

  },
    async createShop(payload) {
      return apiPostShop(payload).then((res) => {
        const { success } = res.data;
        if (success) {
          this.searchShopList();
        }
        return res.data;
      }).catch(err => console.log(err));
    },
    async updateShop(id, payload) {
      return apiPutShop(id, payload).then((res) => {
        const { success } = res.data;
        if (success) {
          this.searchShopList();
        }
        return res.data;
      }).catch(err => console.log(err));
    },
    async deleteShop(id) {
      return apiDeleteShop(id).then((res) => {
        const { success } = res.data;
        if (success) {
          this.searchShopList();
        }
        return res.data;
      }).catch(err => console.log(err));
    },
    setEditForm(id) {
      const shop = this.shopList.find(
        (shop) => shop.shop_id === id
      );
      this.shopDetail = objDeepCopy(shop);
      //スマレジ更新時
     if (this.shopDetail.use_cash_register === '') {
        this.shopDetail.use_cash_register = "";
      } else {
        this.shopDetail.use_cash_register = "1";
      }
    },
    resetForm() {
      this.shopDetail = objDeepCopy(defaultShopDetail);
    }
  },
});
