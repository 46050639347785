import ApiRequest from "@/common/axios";
import { defineStore } from "pinia";

export const LOGIN = 'api/login'
export const SESSION = 'api/selected'

export const useSessionStore = defineStore('session', {
  state: () => ({
    loginUser: {
      line_id: '',
      user_name: '',
      user_category: 2,
      company_id: '',
      shop_id_list: []
    },
    isAuthenticated: false,
    savedMailAddressFlg: false,
    savedMailAddress: '',
    selectedCompanyId: '',
    selectedShopId: '',
    use_cash_register: '',
  }),
  getters: {
    getUserId: (state) => {
      return state.loginUser.line_id
    },
    getUserName: (state) => {
      return state.loginUser.user_name
    },
    getUserCategory: (state) => {
      return state.loginUser.user_category
    },
    getLoginCompanyId: (state) => {
      return state.loginUser.company_id
    },
    getLoginShopId: (state) => {
      return state.loginUser.shop_id_list
    },
    getIsAuthenticated: (state) => {
      return state.isAuthenticated
    },
    getSavedMailAddress: (state) => {
      return state.savedMailAddress
    },
    getSelectedCompany: (state) => {
      return state.selectedCompanyId;
    },
    getSelectedShop: (state) => {
      return state.selectedShopId;
    },
    getUseCashRegister: (state) => {
      return state.use_cash_register;
    }
  },
  actions: {
    // ログイン処理
    async login(payload) {
      return new Promise((resolve, reject) => {
        ApiRequest.post(LOGIN, payload).then((response) => {
          const { success, data } = response.data;
          if (success === true) {
            // ログインユーザ情報
            this.loginUser = data.login_user_info;
            // 認証済みフラグ
            this.isAuthenticated = true;
            // 会社・店舗プルダウン)のセット
            this.selectedCompanyId = data.session_info.selected_company_id;
            this.selectedShopId =  data.session_info.selected_shop_id;
            this.use_cash_register = data.session_info.use_cash_register;
          }
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        })
      })
    },
    // 会社・店舗の選択項目をDBに保存
    async updateSelected(payload) {
      return new Promise(resolve => {
        ApiRequest.post(SESSION, payload).then((response) => {
          const { success } = response.data;
          if (success === true) {
            resolve(response);
          }
        }).catch((err) => console.log(err));
      })
    }
  },
  persist: [
    {
      storage: sessionStorage,
      paths: ['loginUser', 'isAuthenticated', 'selectedCompanyId', 'selectedShopId', 'use_cash_register'],
    },
    {
      storage: localStorage,
      paths: ['savedMailAddress', 'savedMailAddressFlg']
    }
  ]
})
