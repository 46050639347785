<template>
  <div class="require-text-wrapper">
      <div class="require-text">必須</div>
  </div>
</template>

<style>
.require-text-wrapper{
    border-radius: 3px;
    font-size: 14px;
    background-color: #DA615D;
    display: flex;
    line-height: 40px;
    width: 40px;
    height: 24px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    text-align: center;
    margin-left:51px;
    margin-right: 51px;
  }

.require-text{
  color: white;
}
</style>