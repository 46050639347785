<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
const route = useRoute();

const activeIndex = computed(() => {
  // ルートのパスに基づいてアクティブなメニューアイテムを決定
  if (route.path === "/product-list") {
    return "/product-list";
  } else if (route.path === "/product-material-list") {
    return "/product-material-list";
  } else if (route.path === "/product-recipe-list") {
    return "/product-recipe-list";
  } else {
    return "";
  }
});
</script>

<template>
  <el-menu :default-active="activeIndex" mode="horizontal" @select="handleSelect" router>
    <el-menu-item index="/product-list" :route="{ name: 'product' }">{{
      $t("MENU.COST_MANAGEMENT.PRODUCT")
    }}</el-menu-item>
    <el-menu-item index="/product-material-list" :route="{ name: 'material' }">{{
      $t("MENU.COST_MANAGEMENT.MATERIAL")
    }}</el-menu-item>
  </el-menu>
</template>

<style lang="scss" scoped>
.el-menu--horizontal {
  margin: 0px;
  padding-top: 10px;
  height: 40px;
}

.el-menu--horizontal.el-menu {
  border-bottom: none;
  box-shadow: 0px -1px #b7b7b721 inset;
}

.el-menu--horizontal > .el-menu-item {
  margin: 0px 80px 0px 0px;
  padding: 4px;
}

.el-menu--horizontal > .el-menu-item.is-active {
  color: #333 !important;
  background-color: #fff !important;
  border-bottom-color: var(--systemColorAccent);
}

:deep(.el-menu-item) {
  &:hover {
    border-bottom-color: var(--systemColorAccent);
  }
}
</style>
