<template>
  <el-dialog
    v-model="dialogVisible"
    width="30%"
    center
    :before-close="handleCancel"
    :close-on-click-modal="false"
  >
    <el-row justify="center">
      <el-row justify="center" style="margin: auto 48px"
        ><p class="message">{{ props.message }}</p></el-row
      >
      <el-row justify="center">
        <el-button @click="handleConfirmSave" class="Dialog__btn--ok">
          {{ props.save }}
        </el-button>
        <el-button @click="handleConfirmDestroy" class="Dialog__btn">
          {{ props.destroy }}
        </el-button>
        <el-button @click="handleCancel" class="Dialog__btn">
          {{ props.cancel }}
        </el-button
        >
      </el-row>
    </el-row>
  </el-dialog>
</template>
<script setup>
import { defineProps, defineEmits, ref, watch } from "vue";

const props = defineProps({
  message: { type: String, required: true },
  isVisible: { type: Boolean, required: true },
  save: { type: String, required: true},
  destroy: { type: String, required: true},
  cancel: { type: String, required: true},
});
const emit = defineEmits(["save", "destroy", "cancel"]);

const dialogVisible = ref(false);

watch(
  () => props.isVisible,
  (newValue) => {
    dialogVisible.value = newValue;
  }
);

const handleConfirmSave = () => {
  emit("save");
};

const handleConfirmDestroy = () => {
  emit("destroy");
};

const handleCancel = () => {
  emit("cancel");
};
</script>

<style lang="scss" scoped>
.el-row {
  text-align: center;
}

.Dialog__btn {
  display: flex;
  width: 108px;
  height: 26px;
  padding: 2px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  &--ok {
    display: flex;
    width: 108px;
    height: 26px;
    padding: 2px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    color: #fff;
    border-radius: 5px;
    border: none;
    background: var(--main, #0069a3);
  }
}

.Dialog__btn--ok:hover {
  background:var(--hover, #52A4D1);
}

.message {
  font-size: 14px;
}

.el-overlay-dialog {
  top: 25%;
  bottom: 25%;
}

.el-dialog {
  border-radius: 5px;
}

.el-dialog--center .el-dialog__body {
  text-align: initial;
  padding: 20px;
}

.el-dialog__header {
  padding: 8px;
}

.el-dialog__headerbtn {
  position: absolute;
  top: 0px;
  right: 0;
  padding: 0;
  width: 40px;
  height: 40px;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: var(--el-message-close-size,16px);
}
</style>
