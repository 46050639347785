import axios from 'axios'
import { useSessionStore } from '@/stores/auth/session';

const ApiRequest = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  baseBrowserURL: process.env.VUE_APP_BROWSER_BASE_URL,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json'
    // 'Authorization': 'Bearer {トークン}'
  },
  responseType: 'json',
})

// ApiRequest.defaults.withCredentials = true;

ApiRequest.interceptors.request.use(
  (request) => {
    const sessionStore = useSessionStore();
    if (sessionStore.isAuthenticated == true) {
      if (request.method === 'post' || request.method === 'put') {
        request.data["login_user_id"] =  sessionStore.loginUser.line_id;
      }
    }
    return request;
  }, (error) => {
    // リクエストエラーの処理
    return Promise.reject(error);
  }
)

export default ApiRequest;