<script setup>
import { ref, defineEmits } from "vue";
import { useI18n } from "vue-i18n";

// text.ts呼び出し
const { t } = useI18n({});
// 入力値をrefで管理
const search_value = ref("");
// 子から親にデータを渡すためdefineEmitsを定義
const emit = defineEmits(["clickSearchBtn"]);
// 親に検索ボタンクリック時の入力値を渡す
const click = () => {
  emit("clickSearchBtn", search_value.value.trim());
};
</script>

<template>
  <div>
    <el-input
      v-model="search_value"
      @keydown.enter.exact.prevent
      @keyup.enter.exact="click"
      :placeholder="t('COMMON.PLACEHOLDER.SEARCH_KEYWORD')"
    >
      <template #append>
        <CButton
          :text="t('COMMON.BUTTON.SEARCH')"
          :customClass="'search-button'"
          @clickBtn="click"
        >
        </CButton>
      </template>
    </el-input>
  </div>
</template>

<style lang="scss" scoped>
:deep(.search-button) {
  padding: 0 20px;
}

:deep(.el-input__wrapper) {
  width: 360px;
  box-shadow: 0 0 0 2px var(--systemColorMain) inset;
}

:deep(.el-input-group__append) {
  background-color: var(--systemColorMain);
  color: #fff;
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: none;
}

:deep(.el-input-group__append:hover) {
  background-color: #52A4D1;
}
</style>