<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
const route = useRoute();

const activeIndex = computed(() => {
  // ルートのパスに基づいてアクティブなメニューアイテムを決定
  if (route.path === "/annual-sales-target") {
    return "/annual-sales-target";
  } else if (route.path === "/annual-sales-product") {
    return "/annual-sales-product";
  } else if (route.path === "/annual-mq-report") {
    return "/annual-mq-report";
  } else {
    return "";
  }
});
</script>

<template>
  <el-menu :default-active="activeIndex" mode="horizontal" router>
    <el-menu-item index="/annual-sales-target" :route="{ name: 'annual-sales-target' }">{{
      $t("MENU.ANNUAL.SALES_TARGET")
    }}</el-menu-item>
    <el-menu-item index="/annual-mq-report" :route="{ name: 'annual-mq-report' }">{{
      $t("MENU.ANNUAL.MQ_REPORT")
    }}</el-menu-item>
    <el-menu-item
      index="/annual-sales-product"
      :route="{ name: 'annual-sales-product' }"
      >{{ $t("MENU.ANNUAL.MONTHLY_PRODUCT") }}</el-menu-item
    >
  </el-menu>
</template>

<style lang="scss" scoped>
.el-menu--horizontal {
  margin: 0px;
  padding-top: 10px;
  height: 40px;
}

.el-menu--horizontal.el-menu {
  border-bottom: none;
  box-shadow: 0px -1px #b7b7b721 inset;
}

.el-menu--horizontal > .el-menu-item {
  margin: 0px 80px 0px 0px;
  padding: 4px;
}

.el-menu--horizontal > .el-menu-item.is-active {
  color: #333 !important;
  background-color: #fff !important;
  border-bottom-color: var(--systemColorAccent);
}

:deep(.el-menu-item) {
  &:hover {
    border-bottom-color: var(--systemColorAccent);
  }
}
</style>
