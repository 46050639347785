
<script setup>
import { storeToRefs } from 'pinia';
import { useSessionStore } from '@/stores/auth/session';

const sessionStore = useSessionStore();
const { loginUser } = storeToRefs(sessionStore);

</script>

<template>
  <el-header>
    <el-row justify="space-between">
      <img src="@/assets/img/logo/white_horizontal_name.svg" />
      <el-text>
        <img src="@/assets/img/icon/user.svg" />
        <span>{{ loginUser.user_name }}</span>
      </el-text>
    </el-row>
  </el-header>
</template>

<style lang="scss" scoped>
.el-header {
  padding: 18px 24px 0;
  background-color: var(--systemColorMain);
}

.el-text {
  padding: 0px 8px;
  color: #fff;
  display: flex;
}

.el-text>img {
  align-self: center;
}
</style>