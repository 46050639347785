import { defineStore } from "pinia";
import ApiRequest from "@/common/axios";
import {
  apiPostCompany,
  apiGetCompany,
  apiGetCompanys,
  apiPutCompany,
  apiDeleteCompany,
} from "@/apis/master";
import { objDeepCopy } from "@/composables/util";

const MASTER_COMPANY = "api/master-company";

const defaultCompanyDetail = {
  company_id: "",
  company_name: "",
  sales_information_notification: "",
  closing_month: "",
};

export const useCompanyStore = defineStore("company", {
  state: () => ({
    companyList: [],
    companyDetail: objDeepCopy(defaultCompanyDetail),
    shopNoneCompany:[],
    editCompanyId: '',
    search: {
      query: {
        searchValue: "",
      }
    },
  }),
  getters: {
    getSearch: (state) => state.search,
    getEditCompanyId: (state) => state.editCompanyId,
    getCompanyList: (state) => {
      const companyList = objDeepCopy(state.companyList);
      return companyList
    },
    getCompanyAll: (state) => {
      return objDeepCopy(state.companyList)
    },
    getCompanyDetail: (state) => {
      return state.companyDetail;
    },
  },
  actions: {
    async searchListCompany(value) {
      const params = {
        search_value: value,
      };
      return apiGetCompanys(params)
        .then((res) => {
          const { success, data } = res.data;
          if (success === true) {
            this.companyList = data;
          }
        })
        .catch((err) => console.log(err));
    },
    async searchListShopNoneCompany() {
      const ret = await ApiRequest.get(`${MASTER_COMPANY}/shop-none`)
      const{ success, data } = ret.data;
      if(success) {
        this.shopNoneCompany = data;
      }
  },
    async getDetailCompanyById() {
        const ret = await apiGetCompany(this.editCompanyId);
        
        const{ success, data } = ret.data;
        if(success) {
          this.companyDetail.company_id = data.company_id;
          this.companyDetail.company_name = data.company_name;
          this.companyDetail.sales_information_notification = data.sales_information_notification;
          this.companyDetail.closing_month = data.closing_month;
        }

    },
    resetForm() {
      this.companyDetail = objDeepCopy(defaultCompanyDetail);
    },
    async createCompany(payload) {
      return apiPostCompany(payload).then((res) => {
        const { success } = res.data;
        if (success) {
          this.searchListCompany();
        }
        return res.data;
      }).catch(err => console.log(err));
    },
    setEditForm(id) {
      const company = this.companyList.find(
        (company) => company.company_id === id
      );
      this.companyDetail = objDeepCopy(company);
    },
    async updateCompany(id, payload) {
      return apiPutCompany(id, payload).then((res) => {
        const { success } = res.data;
        if (success) {
          this.searchListCompany();
        }
        return res.data;
      }).catch(err => console.log(err));
    },
    async deleteCompany(id) {
      return apiDeleteCompany(id).then((res) => {
        const { success } = res.data;
        if (success) {
          this.searchListCompany();
        }
        return res.data;
      }).catch(err => console.log(err));
    },
  },
  persist: false
});