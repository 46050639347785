<template>
  <el-button
    :class="customClass"
    :type="type"
    @click="click"
    :disabled="disabled"
  >
    <img v-if="icon" :src="icon" />
    {{ text }}
  </el-button>
</template>

<script setup>
import { defineEmits, defineProps } from "vue";

defineProps({
  text: { type: String, default: "", require: false },
  type: { type: String, default: "", require: false },
  customClass: { type: String, default: "", require: false },
  icon: { type: String, default: null, require: false },
  disabled: { type: Boolean, default: false, required: false },
});

const emit = defineEmits(["clickBtn"]);

const click = () => {
  emit("clickBtn");
};
</script>
