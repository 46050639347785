const browser_close_alert = () => {
    window.addEventListener('beforeunload', function (e) {
        // イベントをキャンセルする
        e.preventDefault();
        // Chrome では returnValue を設定する必要がある
        e.returnValue = 'このページを離れますか?';
      });
  }


export default browser_close_alert